import React from 'react'
import { Router } from '@reach/router'
import RAC from '../../../templates/RAC'
import DynamicNotFoundPage from '../../dynamicNotFound'

export default () => (
  <Router>
    <RAC path="/ja/insurance/*" />
    <RAC path="/zh-cn/insurance/*" />
    <RAC path="/zh-tw/insurance/*" />
    <RAC path="/pl/insurance/*" />
    <RAC path="/ru/insurance/*" />
    <RAC path="/it/insurance/*" />
    <RAC path="/de/insurance/*" />
    <RAC path="/es/insurance/*" />
    <RAC path="/fr/insurance/*" />
    <RAC path="/ar/insurance/*" />
    <RAC path="/id/insurance/*" />
    <RAC path="/pt/insurance/*" />
    <RAC path="/ko/insurance/*" />
    <DynamicNotFoundPage default />
  </Router>
)
